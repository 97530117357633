@mixin Scrollbar(
    $width: 8px,
    $height: 8px,
    $bg-color: #c1c1c1,
    $border: 1px solid #708090,
    $shadow: inset 0 0 0 rgba(0, 0, 0, 0.3)
) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: $shadow;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $bg-color;
    }
}

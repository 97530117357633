// Light Theme
@import './assets/scss/bootstrap.scss';
@import './assets/scss/app.scss';

// Dark Theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

//RTL Theme
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";

//Icons
@import './assets/scss/icons.scss';

//Import Custome scss
@import './assets/scss/custom-style.scss';

//
// _footer.scss
//

.footer {
    position: fixed;
    background-color: $footer-bg;
    bottom: 0;
    padding: 10px calc(#{$grid-gutter-width} / 2);
    text-align: center;
    right: 0;
    color: $footer-color;
    left: 240px;
    height: $footer-height-adam;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    .all-right-reserve {
        font-size: 12px;
    }
}
.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: calc(100% - 240px);
    height: 100%;
    z-index: 1;
    left: 240px;
    top: 0;
}

@media (max-width: 992px) {
    .footer {
        left: 0;
    }
    .overlay {
        left: 0;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .footer {
        .all-right-reserve {
            font-size: 10px;
        }
    }
  }

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
    .overlay {
        left: $sidebar-collapsed-width;
        width: calc(100% - 70px);
    }
}

body[data-layout='horizontal'] {
    .footer {
        left: 0 !important;
    }
}

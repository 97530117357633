//
// Forms.scss
//

// checkbox input right
.form-group {
    margin-bottom: 20px;
    .col-form-label {
        line-height: 1;
    }
    .select2 {
        .invalid-feedback {
            display: block;
        }
    }
    &.radio-wrapper {
        margin-bottom: 8px;
        .form-group {
            margin-bottom: 0;
            padding: 0 10px;
        }
        .radio-label {
            margin-bottom: 0;
        }
    }
    .input-group-append {
        .input-group-text {
            padding: 0px 20px;
        }
    }
}

.form-control {
    height: $input-height-adam;
    border-radius: $input-border-radius-adam;
    color: #000000;
    .form-check {
        cursor: pointer;
        margin-right: 25px;
        &-label {
            font-weight: 400;
            line-height: 1.5;
            color: #5b626b;
        }
    }
}

.form-check-right {
    padding-left: 0;
    display: inline-block;
    padding-right: 1.25rem;
    .form-check-input {
        right: 0;
        margin-left: 0;
    }

    .form-check-label {
        display: block;
    }
}

.custom-control-right {
    padding-left: 0;
    padding-right: 1.5rem;
    display: inline-block;
    .custom-control-label {
        display: inline-block;
        &:before,
        &:after {
            left: auto;
            right: -1.5rem;
        }
    }

    .custom-control-input {
        left: auto;
    }
}

.react-checkbox-tree {
    .rct-node {
        .rct-title {
            font-weight: 400;
            line-height: 2;
            font-size: 13px;
        }
    }
}

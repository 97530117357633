iframe {
  display: none!important;
}
.dataTables_paginate {
  .pagination {
    float: right;
  }
}

.dataTables_filter {
  label {
    float: right;
  }
}

.table-nowrap tbody tr td {
  white-space: break-spaces!important;
}

.rangeslider__fill {
  background-color: #007bff !important;
}

.select2.is-invalid .css-18a9yly-control {
  border-color: red;
}

// .table tbody > tr > td, body tr.sortableHelper > td, body tr > td{
// 	width: auto;
// }

th {
  text-transform: capitalize;
}

body.vertical-collpsed #root #layout-wrapper .vertical-menu div #sidebar-menu{
  position: sticky;
  top: 70px;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li ul.sub-menu li a {
  padding-left: 5.3rem;
}

.table-compact {
  overflow-y: auto;
  height: 100px;
  border-spacing: 0 3px;
  cursor: default;

  tbody tr {
    &:hover {
      box-shadow: none;
    }

    &:hover > td {
      font-weight: normal !important;
    }

    td .form-group:last-child {
      margin-bottom: 0;
    }

    td {

      &:last-child {
        white-space: nowrap!important;
      }

      white-space: inherit;
      padding: 5px 15px;
      height: 45px;
      font-size: 13px;
      color: #000;
      min-width: auto !important;
      .form-group label {
        font-weight: normal;
      }
      .pdf-action {
        min-width: 180px;
        justify-content: center;
      }
    }
  }
}

.table-report{
  background: white;
  tbody tr td {
    height: 35px!important;
    width: 215px!important;
  }
} 

.table-bordered {
  background-color: #fff !important;
  border-spacing: 0 3px;
  cursor: default;
  border-collapse: collapse;
  // thead{
  //   position: sticky !important;
  // top: 0;

  // }
  thead tr th {
    background-color: #f8f9fa;
    border: 0.5px solid #e9ecef !important;
    &:last-child{
      min-width: 180px;
    }
  }
  
  tbody tr {
    &:hover {
      box-shadow: none;
    }

    &:hover > td {
      font-weight: normal !important;
    }

    td .form-group:last-child {
      margin-bottom: 0;
    }

    td {
      white-space: inherit;
      padding: 5px 15px;
      height: 45px;
      font-size: 13px;
      background-color: #fff;
      border: 1px solid #e9ecef;
      min-width: auto !important;
      .form-group label {
        font-weight: normal;
      }
    }
  }
}

.nav-tabs > li > a {
  padding: 10px 50px;
  font-size: 14px;
}

.shiftTabs > li > a {
  padding: 10px 25px;
}

.form-check-input {
  position: relative;
  margin-left: 0 !important;
}

a.btn.btn-lg.medium {
  font-size: 1.1em;
  padding: 7px 20px;
}

.bg-red > td {
  background-color: red !important;
  color: white !important;
}

.complexities [class*="-ValueContainer"] {
  flex-wrap: nowrap !important;
  white-space: nowrap;
}
.popover-header {
  background: #fff !important;
  color: var(--primary);
  border-bottom: 1px solid var(--secondary);
  text-align: center;
}
.new-td-align-top {
  vertical-align: top !important;
}

.badge-pill {
  padding: 0.3em 0.5em!important;
}

.col-form-label{
  text-transform: capitalize;
}
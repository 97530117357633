//
// _table.scss
//

%table-th {
    font-weight: $font-weight-bold;
    text-align: left;
    color: #000000;
    border: none;
    height: 45px;
    padding: 7px 15px;
    z-index: 1;
    background-color: #f0f1f4;
    white-space: nowrap;
    border-bottom: none !important;
    vertical-align: middle !important;
    &.index-column {
        width: 60px;
    }
}
%table-td {
    font-size: 13px;
    text-align: left;
    color: #5b626b;
    border: none;
    height: 50px;
    padding: 10px 15px;
    min-width: 160px;
    white-space: nowrap;
    background-color: #f0f1f4;
    vertical-align: middle !important;
    // word-break: break-word;
    // white-space: break-spaces;
    &.action {
        min-width: 280px;
    }
}

.table {
    padding: 5px 0;
    cursor: grab;
    border-collapse: separate;
    border-spacing: 0 4px;
    width: 100%;
    overflow: visible;
    margin-bottom: 0;
    &-responsive {
        @include Scrollbar;
        &.indiana-scroll-container {
            &.indiana-scroll-container--dragging {
                cursor: grabbing;
            }
        }
    }
    thead {
        > tr {
            > th {
                @extend %table-th;
                cursor: default !important;
                span {
                    font-size: 25px;
                }
            }
        }
    }
    tbody {
        > tr {
            > th {
                @extend %table-th;
                span {
                    font-size: 25px;
                }
            }
            > td {
                @extend %table-td;
            }
            &:hover {
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
                    0 3px 6px rgba(0, 0, 0, 0.23);
                > th {
                    font-weight: 500;
                    color: #292d32;
                    cursor: default !important;
                }
                > td {
                    font-weight: 500;
                    color: #292d32;
                    cursor: default !important;
                }
            }
        }
    }
}

//Table centered
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

body {
    tr {
        > td {
            width: inherit !important;
        }
    }
    tr {
        &.sortableHelper {
            overflow: hidden;
            z-index: 0;
            cursor: row-resize;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
                0 3px 6px rgba(0, 0, 0, 0.23);
            > th {
                @extend %table-th;
                span {
                    color: #7a6fbe;
                    font-size: 25px;
                }
            }
            > td {
                @extend %table-td;
                width: inherit !important;
                font-weight: 500 !important;
                color: #7a6fbe !important;
            }
        }
        > th {
            @extend %table-th;
            span {
                color: #7a6fbe;
                font-size: 25px;
            }
        }
        > td {
            @extend %table-td;
            width: 100%;
        }
    }
}

.table-Select {
    padding: 10px 15px;
    background-color: #f0f1f4;
    form {
        display: flex;
        align-items: center;
    }
    .action {
        margin-left: 10px;
        text-align: right;
    }
}
@media (max-width: 576px) {
    .table-Select {
        form {
            display: block;
            align-items: inherit;
        }
        .action {
            margin-top: 10px;
            text-align: right;
        }
    }
}

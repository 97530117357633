.loginParent{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./../../assets/images/login-bg.jpg') no-repeat;
    background-size: contain;
}

.position-absolute-lg{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

//
// right-sidebar.scss
//

.right-bar {
    background-color: #eaeaea;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width-adam;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width-adam + 10px);
    top: 0;
    bottom: 0;

    .rightbar-title {
        background-color: #f0f1f4;
        h5 {
            color: #000000;
            span {
                font-size: 22px;
            }
        }
    }
    .right-bar-toggle {
        background-color: lighten($dark, 7%);
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        color: $gray-200;
        text-align: center;
        border-radius: 50%;

        &:hover {
            background-color: lighten($dark, 10%);
        }
    }
}

// Rightbar overlay
.rightbar-overlay {
    background-color: rgba($dark, 0.55);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all 0.2s ease-out;
}

.right-bar-enabled {
    overflow: hidden;
    .right-bar {
        right: 0;
    }
    .rightbar-overlay {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}

// directory

.directory-card {
    overflow: hidden;
    .directory-bg {
        background-image: url('../../../images/directory-bg.jpg');
        background-size: cover;
        background-position: center center;
    }
    .directory-overlay {
        background-color: rgba($primary, 0.7);
        padding: 20px;
    }
    img {
        position: relative;
        top: 60px;
    }
}

//For Inside Modal

.modal {
    .directory {
        &-card {
            border-radius: 0;
            margin: 0;
            .directory-overlay {
                background-color: rgba($primary, 0.7);
                padding: 0px;
            }
            img {
                position: relative;
                top: 40px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
                    0 3px 6px rgba(0, 0, 0, 0.23);
            }
        }
        &-content {
            padding: 25px 14px 0;
            position: relative;
            .status {
                top: 10px;
                right: 10px;
                padding: 4px 10px;
                border-radius: 0;
            }
            .bold-text {
                color: #343a40;
                font-weight: 500;
            }
            .person-Details {
                padding: 0 10px;
                margin-bottom: 15px;
            }
            .more-info {
                margin-right: -15px;
                &-card {
                    margin-right: 10px;
                    max-width: calc(100% / 2 - 10px);
                    .info {
                        margin-top: 10px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .text-muted {
                            font-size: 11px;
                        }
                        .icon {
                            line-height: 1;
                            display: flex;
                            align-items: center;
                            span {
                                display: flex;
                                align-items: center;
                                font-size: 20px;
                                line-height: 1;
                                i {
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
            .btn-secondary {
                border-radius: 0;
                color: #000000;
                border: none;
                background-color: #d5d5d5;
            }
        }
    }
}

.modal {
  // &-dialog {
  //     max-width: 600px;
  // }
  &-content {
    border-radius: 0px;
    border: none;
  }
  &-body {
    padding: 0;
  }
  &-footer {
    border: none;
    background-color: #ffffff;
  }
}
